export default {
    // 104: Irlande
    104: [
        "CO. CARLOW",
        "CO. CAVAN",
        "CO. CLARE",
        "CO. CORK",
        "CO. DONEGAL",
        "CO. DUBLIN",
        "CO. GALWAY",
        "CO. KERRY",
        "CO. KILDARE",
        "CO. KILKENNY",
        "CO. LAOIS",
        "CO. LEITRIM",
        "CO. LIMERICK",
        "CO. LONGFORD",
        "CO. LOUTH",
        "CO. MAYO",
        "CO. MEATH",
        "CO. MONAGHAN",
        "CO. OFFALY",
        "CO. ROSCOMMON",
        "CO. SLIGO",
        "CO. TIPPERARY",
        "CO. WATERFORD",
        "CO. WESTMEATH",
        "CO. WEXFORD",
        "CO. WICKLOW"
    ]
}