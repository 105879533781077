import Request from '@/assets/js/requests.js'
import Constants from '../../config.local.js'
import Common from '@/assets/js/common.js'
import PhoneTransformer from '../assets/js/dexie/transformers/PhoneTransformer.js'
import parsePhoneNumber from 'libphonenumber-js'
import { parsePhoneNumberFromString as parseMax } from 'libphonenumber-js/max'

var PhoneMixin = {
	data() {
		return {

		}
	},
	methods: {
		loadPhoneTiers: async function(tiers_id) {
			return this.$storage.db.t('phone')
			.then(table => {
				return table.where({
					phone_tiers: parseInt(tiers_id)
				})
			})
			.then(col => {
				return col.transform(new PhoneTransformer())
			})
		},

		loadPhoneContact: async function(contact_id) {
			const params = { 'contact_id': contact_id }
			const url = this.constructRoute(Constants.CONTACT_PHONE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("PhoneMixin::loadPhoneContact", url)
			.catch(error => {
				console.error("PhoneMixin::loadPhoneContact => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		addPhone: async function(params) {
			if(params.phone_numero !== "") {
				const indicatifs_domtom = ['+262', '+590', '+594', '+596']
				if(!parseMax(params.phone_indicatif+params.phone_numero, params.phone_country).isValid()
					&& (!indicatifs_domtom.includes(params.phone_indicatif) || params.phone_country != 'FRA'
					|| !parseMax('+33' + params.phone_numero, params.phone_country).isValid())) {
					throw "toast.phone_not_valid"
				}
				else {
					const phone_formatted = parsePhoneNumber(params.phone_indicatif+params.phone_numero, params.phone_country)
					params.phone_numero = phone_formatted.nationalNumber
				}
			}

			const phone_id = Common.getNegativeId()
			let phone_type = params.phone_type
			if(phone_type == this.getTrad('tiers.phone_type.fixe')) {
				phone_type = 'fixe'
			}
			else if(phone_type == this.getTrad('tiers.phone_type.portable')) {
				phone_type = 'portable'
			}
			return this.$storage.db.t('phone')
			.then(table => {
				return table.add({
					phone_id: phone_id,
					phone_valide: 1,
					phone_contact: parseInt(params.contact_id),
					phone_tiers: parseInt(params.tiers_id),
					phone_indicatif: params.phone_indicatif,
					phone_country: params.phone_country,
					phone_numero: params.phone_numero,
					phone_type: phone_type
				})
			})
		},

		deleteAllPhone: async function(contact_id) {
			return this.$storage.db.t('phone')
			.then(table => {
				return table
				.where('phone_contact')
				.equals(parseInt(contact_id))
				.invalid()
			})
		},
	}
}

export default PhoneMixin
